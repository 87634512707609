import React, { FunctionComponent, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from 'gatsby';
import { WindowLocation } from '../types/PageProps';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/layout';
import theme from '../theme';

const secondary = theme.palette.secondary.main;

const JoinOurTeam: FunctionComponent<PageProps> = ({ location }) => {
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);

  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
  }, []);

  return (
    <Layout location={location}>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Dolores Hair Designers</title>
        <link rel="canonical" href="http://doloreshairdesigners.com/about" />
      </Helmet>
      <Typography align="center" variant="h2" >
        <Typography variant="h1" style={{paddingTop: 100, color: secondary}}>
          JOIN OUR TEAM!
        </Typography>
        <div style={{padding: 25}}>
          Why ???
        </div>
        <div style={{padding: 25}}>
          Do you want to succeed?
        </div>
        <div style={{padding: 25}}>
          Do you want to make more money?
        </div>
        <div style={{padding: 25}}>
          Do you want to work in a beautiful, newly renovated salon?
        </div>
        <div style={{padding: 25}}>
          We have been at the same location for thrity-seven years.
        </div>
        <div style={{padding: 25}}>
          We are established!!
        </div>
        <div style={{paddingTop: 50, marginRight: 1150}}>
          We can offer:
        </div>
        <div style={{paddingRight: 500}}>
          - Beautiful working conditions
        </div>
        <div style={{paddingRight: 993}}>
          - Free WiFi
        </div>
        <div style={{paddingRight: 508}}>
          - Washer & Dryer on premise
        </div>
        <div style={{paddingRight: 718}}>
          - Coffee & pastry bar
        </div>
        <div style={{paddingRight: 890}}>
          - Big screen tv
        </div>
        <div style={{paddingRight: 540}}>
          - Three color halo machines
        </div>
        <div style={{paddingTop: 50}}>
          Above all else, we encourage education. We do like to take educational trips. Sometimes we end up at the beach or a fun city (New Orleans)!!
          We are currently offering special prices for New Stylists'.
        </div>
        <div style={{padding: 25}}>
          Call me for more information.
        </div>
        <div style={{padding: 25}}>
          614-889-4808
        </div>
      </Typography>
    </Layout>
  );
};

export default JoinOurTeam;